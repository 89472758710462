import React from 'react'
import { CircularProgress, debounce, TextField } from '@mui/material'
import SearchIcon from 'assets/icons/SearchIcon'
import { BookListStyled, SearchPanelStyled } from './SearchPanel.style'
import CancelIcon from 'assets/icons/CancelIcon'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import get from 'lodash.get'
import { useOutsideHandler } from 'hooks/useOutSideHandler/useOutSideHandler'
import { IBookList } from './SearchPanel.types'
import ChevronRightIcon from 'assets/icons/ChevronRightIcon'
import formatter from 'services/formatter'
import { IBook } from 'types/common'




const BookList: React.FC<IBookList> = ({ data, isLoading, total, onClose, search }) => {
    const navigate = useNavigate()
    const { t } = useTranslation();



    const bookCardClick = (link: string | undefined, _id: string) => {
        navigate({ pathname: `/books/details/${link || _id}` })
        onClose();
    }

    return <BookListStyled>
        <div className='search-popover-header d-flex justify-content-between mb-3'>
            <div className='search-popover-label'>{t("NAVBAR.BOOKS")} ({total})</div>
            <Link to={{ pathname: "/books", search: `?page=1&limit=24&search=${search}` }} className='d-flex align-items-center search-popover-all-views' onClick={onClose}>
                {t("NAVBAR.ALL_VIEWS")}
                <span className='ms-1'>
                    <ChevronRightIcon width="14px" height="14px" />
                </span>
            </Link>
        </div>
        {
            isLoading ?
                <div className='popver-loader d-flex justify-content-center p-3'>
                    <CircularProgress
                        size={25}
                        thickness={3}
                        color="warning"
                        disableShrink
                        sx={{
                            animationDuration: '400ms',

                        }}
                    />
                </div> :
                get(data, "length") === 0 ?
                    <div className='text-center no-books-text'>{t("NAVBAR.NOT_BOOKS")}</div>
                    : (
                        <ul className='search-popover-list list-unstyled m-0 p-0'>
                            {
                                data.map((book: IBook) => <li
                                    onClick={() => bookCardClick(book.link, book._id)}
                                    className="search-popover-list-item d-flex mb-2"
                                    key={book._id}
                                >
                                    <img src={process.env.REACT_APP_BASE_URL + book.imgUrl} className='search-popover-list-img' alt={book.name} />
                                    <div className='d-flex flex-grow justify-content-between ps-2'>
                                        <div>
                                            <p className="popover-list-name mb-2">
                                                {get(book, "name", "")}
                                            </p>

                                            <p className="popover-list-author-name">
                                                {get(book, "author.fullName", "")}
                                            </p>


                                        </div>
                                        <p className="popover-list-price">
                                            {formatter(book.bookPrice)}
                                        </p>
                                    </div>
                                </li>)
                            }

                        </ul>
                    )
        }

    </BookListStyled>
}

const SearchPanel = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const searchContentRef = React.useRef<HTMLDivElement>(null)
    const [isVisibleInput, setIsVisibleInput] = React.useState(false)
    const { formState, handleSubmit, setFocus, register, setValue, watch } = useForm()
    const searchValue = watch("search", false);


    const mutationFn = async ({ search }: { search: string }) => {
        try {
            const response = await axios.get(`book?page=1&limit=4&search=${search}`);
            return response;
        } catch (err) {
            throw err;
        }
    }


    const searchMutation = useMutation({
        mutationKey: ['getBookList/search'],
        mutationFn,
    })


    const handleInputChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchMutation.mutate({ search: event.target.value?.trim() })
        setValue(event.target.name, event.target.value)
    }


    const clearInput = () => {
        setValue('search', "");
        searchMutation.mutate({ search: "" })
    }


    const searchIconClick = () => {
        setFocus('search');
        setIsVisibleInput(prev => !prev)
    }

    const onSubmit = (data: any) => {
        navigate({ pathname: '/books', search: `?page=1&limit=24&search=${get(data, "search")}` })
    }

    const onClose = () => {
        setIsVisibleInput(false);
        setValue('search', "");
    }

    useOutsideHandler(searchContentRef, onClose);


    React.useEffect(() => {
        searchMutation.mutate({ search: "" })
    }, [])




    return (
        <SearchPanelStyled onSubmit={handleSubmit(onSubmit)} isVisibleInput={isVisibleInput}>
            <div ref={searchContentRef}>
                <TextField
                    {...register('search',)}
                    onChange={debounce(handleInputChnage, 300)}
                    className="search-input"
                    InputProps={{
                        endAdornment: <div className='search-icon-wrap d-flex align-items-center'>
                            <div className='hover cancel-icon d-flex align-items-center' onClick={clearInput} style={{ visibility: searchValue ? "visible" : "hidden" }}>
                                <CancelIcon width="10px" height="10px" />
                            </div>


                            <div className='line mx-1'></div>
                            <button onClick={searchIconClick} type="button" className='hover d-flex align-items-center search-icon'>
                                <SearchIcon />
                            </button>

                        </div>,
                    }}
                />
                {
                    isVisibleInput && (
                        <BookList
                            data={get(searchMutation, "data.data.data.data", [])}
                            isLoading={searchMutation.isLoading}
                            total={get(searchMutation, "data.data.data.total", 0)}
                            onClose={onClose}
                            search={searchValue}
                        />
                    )
                }

            </div>

        </SearchPanelStyled>
    )
}

export default SearchPanel