import React, { useState } from 'react'
import firebase from 'firebase/app';
import 'firebase/messaging';
import { useEffect, Suspense } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "services/api/client";
import get from "lodash.get";
import LayoutProvider from "layout/context/LayoutProvider";
import ThemeWrapper from "theme/ThemeWrapper";
import { Loader } from "components";
import { firebaseConfig, VAPID_API_KEY } from 'contants/firebase';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => console.log(get(error, "response"), "Global error"),
      queryFn: async ({ queryKey: [url] }) => {
        try {
          if (typeof url === 'string') {
            const res = await axios(process.env.REACT_APP_BASE_URL + url)
            return res
          }
          throw new Error("Query key error")
        } catch (err) {
          throw err
        }

      },
      retry: 0,
      refetchOnWindowFocus: false
    }
  }
})


function App() {



  useEffect(() => {
    const metaTags: any = document.getElementsByTagName("meta");
    metaTags["description"].content =
      "📚 Keng tanlov; 💰 Eng arzon narxlar; 🚚 Tezkor va xavfsiz yetkazib berish. 📦 Kitoblar katalogi va buyurtma";
  }, []);

  // useEffect(() => {

  //   if (!firebase.apps.length) {
  //     firebase.initializeApp(firebaseConfig);
  //   }
  //   const messaging = firebase.messaging();


  //   const getFirebaseToken = async () => {
  //     try {
  //       const token = await messaging.getToken({ vapidKey: VAPID_API_KEY });
  //       return token;
  //     } catch (error) {
  //       console.log('FCM Token olishda xatolik:', error);
  //       throw error;
  //     }
  //   };

  //   getFirebaseToken()
  //     .then((token) => {
  //       axios.put('/fcm-token', { fcmToken: token })
  //     })
  //     .catch((error) => {
  //       console.log('Error:', error);
  //     });

  // }, [])

  // useEffect(() => {
  //   const handleNotification = (payload: any) => {
  //     // Qabul qilingan xabarlarni ushlab qo'yish lozim
  //     console.log('Notification Received:', payload);
  //   };

  //   const unsubscribe = firebase.messaging().onMessage(handleNotification);

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);




  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <LayoutProvider>
          <>
            <HelmetProvider>
              <Helmet>
                <title>Book.uz - Kitob eng yaxshi sovg'a</title>
                <meta
                  name="description"
                  content="📚 Keng tanlov; 💰 Eng arzon narxlar; 🚚 Tezkor va xavfsiz yetkazib berish. 📦 Kitoblar katalogi va buyurtma"
                />
              </Helmet>
            </HelmetProvider>
            <ThemeWrapper />
          </>
        </LayoutProvider>
      </Suspense>


    </QueryClientProvider>

  );
}

export default App;
